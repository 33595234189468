import { PanelOptionsEditorBuilder } from '@grafana/data';
import { MapPanelOptions } from '../types/types';

export function geojsonlayerOptions<T extends MapPanelOptions>(builder: PanelOptionsEditorBuilder<T>) {
  const category = ['GeoJson Editor'];

  builder
    .addBooleanSwitch({
      path: 'geojson.isGeoJsonLayer',
      name: 'Enable Geo Json Layer',
      defaultValue: false,
      category: ['GeoJson Editor'],
    })
    .addTextInput({
      path: 'geojson.pathExt',
      name: 'Provide External Url',
      description: 'Choose an geojson url to view.',
      defaultValue: '',
      category: ['GeoJson Editor'],
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addSelect({
      path: 'geojson.path',
      name: 'Select folder',
      defaultValue: '',
      category: ['GeoJson Editor'],
      settings: {
        options: [
          { value: '', label: 'None' },
          { value: 'public/maps/airports.geojson', label: 'public/maps/airports' },
          { value: 'public/maps/usa-states.geojson', label: 'public/maps/usa-states' },
        ],
      },
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addBooleanSwitch({
      path: 'geojson.useGooglePin',
      name: 'Use Google Pin Marker',
      defaultValue: true,
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addSelect({
      path: 'geojson.iconSymbol',
      name: 'Marker Symbol',
      settings: {
        options: [
          { value: 0, label: 'CIRCLE' },
          { value: 1, label: 'FORWARD_CLOSED_ARROW' },
          { value: 2, label: 'FORWARD_OPEN_ARROW' },
          { value: 3, label: 'BACKWARD_CLOSED_ARROW' },
          { value: 4, label: 'BACKWARD_OPEN_ARROW' },
        ],
      },
      defaultValue: 0,
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer && !options.geojson.useGooglePin,
    })
    .addColorPicker({
      path: 'geojson.fillcoloricon',
      name: 'Icon Fill Color',
      defaultValue: '#f40',
      category,
      showIf: (options: MapPanelOptions) => {
        if (options.geojson.isGeoJsonLayer) {
          return true;
        }
        if (options.geojson.isGeoJsonLayer && !options.geojson.useGooglePin) {
          return true;
        }
        return false;
      },
    })
    .addColorPicker({
      path: 'geojson.strokecoloricon',
      name: 'Icon Stroke Color',
      defaultValue: '#f00',
      category,
      showIf: (options: MapPanelOptions) => {
        if (options.geojson.isGeoJsonLayer) {
          return true;
        }
        if (options.geojson.isGeoJsonLayer && !options.geojson.useGooglePin) {
          return true;
        }
        return false;
      },
    })
    .addNumberInput({
      path: 'geojson.iconSize',
      name: 'Marker Size',
      defaultValue: 6,
      category,
      showIf: (options: MapPanelOptions) => {
        if (options.geojson.isGeoJsonLayer && !options.geojson.useGooglePin) {
          return true;
        }
        return false;
      },
    })
    .addNumberInput({
      path: 'geojson.strokeWeight',
      name: 'Marker/Line Stroke Weight',
      defaultValue: 3,
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addColorPicker({
      path: 'geojson.fillcolor',
      name: 'Polygon Fill Color',
      defaultValue: '#af0',
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addColorPicker({
      path: 'geojson.strokecolor',
      name: 'Line and Polygon Stroke Color',
      defaultValue: '#0f0',
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addNumberInput({
      path: 'geojson.fillOpacity',
      name: 'Polygon Fill Opacity',
      defaultValue: 0.5,
      settings: {
        min: 0,
        max: 1,
        step: 0.1,
      },
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    })
    .addNumberInput({
      path: 'geojson.opacityIcon',
      name: 'Point Fill Opacity',
      defaultValue: 0.5,
      settings: {
        min: 0,
        max: 1,
        step: 0.1,
      },
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer && !options.geojson.useGooglePin,
    })
    .addNumberInput({
      path: 'geojson.strokeOpacity',
      name: 'Line and Polygon Fill Opacity',
      defaultValue: 0.5,
      settings: {
        min: 0,
        max: 1,
        step: 0.1,
      },
      category,
      showIf: (options: MapPanelOptions) => options.geojson.isGeoJsonLayer,
    });
}
