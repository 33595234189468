import { SelectableValue } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

interface ImageList {
  images: SelectableValue[];
  error: null | Error;
}

/**
 * Retrieves a list of images from the S3 server.
 *
 * @return {Promise<ImageList>} A Promise that resolves to an ImageList object containing the list of images and any error that occurred during the retrieval.
 */
export const loadS3Images = async (): Promise<ImageList> => {
  const resp: ImageList = {
    images: [],
    error: null,
  };
  try {
    const response = await getBackendSrv().get('/api/image/');
    if (response.items) {
      const availableImgTemp: SelectableValue[] = [];
      response.items.forEach((item: any) => {
        availableImgTemp.push({ label: item, value: item });
      });
      resp.images = availableImgTemp;
    } else if (response.message) {
      resp.error = Error(response.message);
    } else {
      resp.error = Error('Failed to load images.');
    }
  } catch (err) {
    resp.error = Error(err);
  }
  return resp;
};
