import { AnnotationEvent } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

export const addAnnotations = async (event: AnnotationEvent, panelID: number) => {
  const dash = getDashboardSrv().getCurrent();
  const panel = dash.panels.filter((item) => item.id === panelID)[0];
  getBackendSrv().post('/api/annotations', event);
  panel.refresh();
  return;
};

export const deleteAnnotations = async (annotationID: string, panelID: number) => {
  const dash = getDashboardSrv().getCurrent();
  const panel = dash.panels.filter((item) => item.id === panelID)[0];
  getBackendSrv().delete(`/api/annotations/${annotationID}`);
  panel.refresh();
  return;
};

export const upDateAnnotations = async (event: AnnotationEvent, panelID: number) => {
  const dash = getDashboardSrv().getCurrent();
  const panel = dash.panels.filter((item) => item.id === panelID)[0];
  getBackendSrv().put(`/api/annotations/${event.id}`, event);
  panel.refresh();
  return;
};
