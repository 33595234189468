import { merge } from 'lodash';
import { getTheme } from '@grafana/ui';
import {
  BuildInfo,
  DataSourceInstanceSettings,
  FeatureToggles,
  GrafanaConfig,
  GrafanaTheme,
  GrafanaThemeType,
  LicenseInfo,
  PanelPluginMeta,
  systemDateFormats,
  SystemDateFormatSettings,
} from '@grafana/data';

export class CarbonBootConfig implements GrafanaConfig {
  datasources: { [str: string]: DataSourceInstanceSettings } = {};
  panels: { [key: string]: PanelPluginMeta } = {};
  minRefreshInterval = '';
  appUrl = '';
  appSubUrl = '';
  appName = '';
  windowTitlePrefix = '';
  buildInfo: BuildInfo = {} as BuildInfo;
  newPanelTitle = '';
  bootData: any;
  externalUserMngLinkUrl = '';
  externalUserMngLinkName = '';
  externalUserMngInfo = '';
  allowOrgCreate = false;
  disableLoginForm = false;
  defaultDatasource = '';
  alertingEnabled = false;
  ngAlertingEnabled = false;
  reportingEnabled = false;
  alertingErrorOrTimeout = '';
  alertingNoDataOrNullValues = '';
  alertingMinInterval = 1;
  authProxyEnabled = false;
  exploreEnabled = false;
  ldapEnabled = false;
  sigV4AuthEnabled = false;
  googleMapsKey = '';
  dbMinInterval = '';
  carbonChatBaseUrl = '';
  carbonChatWebsiteToken = '';
  carbonSyncEnabled = false;
  carbonMLEnabled = false;
  carbonMLDatasource = '';
  enableOpenDashboard = false;
  samlEnabled = false;
  autoAssignOrg = true;
  verifyEmailEnabled = false;
  oauth: any;
  disableUserSignUp = false;
  loginHint: any;
  passwordHint: any;
  loginError: any;
  navTree: any;
  viewersCanEdit = false;
  editorsCanAdmin = false;
  disableSanitizeHtml = false;
  theme: GrafanaTheme;
  pluginsToPreload: string[] = [];
  featureToggles: FeatureToggles = {
    live: false,
    meta: false,
    // ngalert: false,
    panelLibrary: false,
    reportVariables: false,
  };
  licenseInfo: LicenseInfo = {} as LicenseInfo;
  rendererAvailable = false;
  http2Enabled = false;
  dateFormats?: SystemDateFormatSettings;
  sentry = {
    enabled: false,
    dsn: '',
    customEndpoint: '',
    sampleRate: 1,
  };
  pluginAdminEnabled = false;
  marketplaceUrl?: string;
  expressionsEnabled = false;
  customTheme?: any;
  awsAllowedAuthProviders: string[] = [];
  awsAssumeRoleEnabled = false;

  constructor(options: CarbonBootConfig) {
    this.theme = options.bootData.user.lightTheme ? getTheme(GrafanaThemeType.Light) : getTheme(GrafanaThemeType.Dark);
    const bg1 = options.bootData.user.carbonBg1;
    const bg2 = options.bootData.user.carbonBg2;

    const override = {
      bg2: bg1,
      pageHeaderBg: bg1,
      formInputBg: bg1,
      formValidationMessageText: bg1,
      formSwitchDot: bg1,
      formCheckboxCheckmark: bg1,
      border2: bg1,
      formSwitchBg: bg1,
      formSwitchBgDisabled: bg1,
      formInputBorder: bg1,
      dropdownOptionHoverBg: bg1,
      dashboardBg: bg1,
      bg1: bg2,
      panelBg: bg2,
      bodyBg: bg2,
      bg3: bg2,
      formInputBgDisabled: bg2,
    };
    this.theme.palette.gray15 = bg1;
    this.theme.palette.gray7 = bg1;
    Object.assign(this.theme.colors, override);

    const defaults = {
      datasources: {},
      windowTitlePrefix: 'Arnowa',
      panels: {},
      newPanelTitle: 'Panel Title',
      playlist_timespan: '1m',
      unsaved_changes_warning: true,
      appUrl: '',
      appSubUrl: '',
      buildInfo: {
        version: 'v1.0',
        commit: '1',
        env: 'production',
        isEnterprise: false,
      },
      viewersCanEdit: false,
      editorsCanAdmin: false,
      disableSanitizeHtml: false,
    };

    merge(this, defaults, options);

    if (this.dateFormats) {
      systemDateFormats.update(this.dateFormats);
    }
  }
}

const bootData = (window as any).carbonBootData || {
  settings: {},
  user: {},
  navTree: [],
};

const options = bootData.settings;
options.bootData = bootData;

/**
 * Use this to access the {@link CarbonBootConfig} for the current running Grafana instance.
 *
 * @public
 */
export const config = new CarbonBootConfig(options);
