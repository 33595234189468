import uPlot, { Axis, Cursor, BBox } from 'uplot';
import { DataFrame, getColorForTheme, GrafanaTheme, getValueFormat, dateTimeParse } from '@grafana/data';
import { BoxPlotData, TooltipInterface } from '../types';
import { calculateFontSize } from '@grafana/ui';
import { Quadtree, Rect, pointWithin } from '../quadtree';
import { formatTime } from '../utils';
import tinycolor from 'tinycolor2';
import darkTheme from '@grafana/ui/src/themes/dark';

const localeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
};

export interface CandlesOptions {
  data: DataFrame;
  ohlcData: {
    open: string;
    high: string;
    low: string;
    close: string;
  };
  total: boolean;
  queryIndex: any;
  dataAll: BoxPlotData;
  toCluster: number;
  clusterSwitch: boolean;
  customClusterSize: number;
  upcolor: any;
  risecolor: any;
  barlineSwitch: boolean;
  labelSwitch: boolean;
  fallcolor: any;
  downcolor: any;
  onHover?: (seriesIdx: number, valueIdx: any) => void;
  onLeave?: (seriesIdx: number, valueIdx: any) => void;
  timeZone: string;
}

/**
 * @typedef {Object} CandleOptions
 * @property {DataFrame} data
 * @property {Object} ohlcData
 * @property {boolean} total
 * @property {Array<number>} queryIndex
 * @property { BoxPlotData} dataAll
 * @property {number} toCluster
 * @property {number} customClusterSize
 * @property {string} risecolor
 * @property {boolean} labelSwitch
 * @property {boolean} barlineSwitch
 * @property {function} onHover
 * @property {function} onLeave
 * @property {string} timeZone
 * @property {string} fallcolor
 * @property {string} upcolor
 * @property {string} downcolor
 */

/**
 * @constant
 * @default
 */
const pxRatio = devicePixelRatio;
const VALUE_MIN_FONT_SIZE = 8;
const VALUE_MAX_FONT_SIZE = 30;
const LABEL_OFFSET_MAX_VT = 5;

export function getConfig(opts: CandlesOptions, theme: GrafanaTheme) {
  const { dataAll, toCluster, clusterSwitch, customClusterSize, risecolor, fallcolor } = opts;
  let qt: Quadtree;
  let hovered: Rect | undefined;
  let vSpace = Infinity;
  let hSpace = Infinity;
  let barMark = document.createElement('div');

  barMark.classList.add('bar-mark');
  barMark.style.position = 'absolute';
  barMark.style.background = 'rgba(255,255,255,0.4)';

  // hide crosshair cursor & hover points
  const cursor: Cursor = {
    x: true,
    y: false,
    points: {
      show: false,
    },
  };
  const select: Partial<BBox> = {
    show: false,
  };
  const init = (u: uPlot) => {
    let over = u.root.querySelector('.u-over')! as HTMLElement;
    over.style.overflow = 'hidden';
    over.appendChild(barMark);
  };

  /**
   * bars with lines and without lines are made here for waterfall chart
   * when waterfall chart is selected with type query.
   */
  const drawWaterfall = (u: uPlot) => {
    /**
     * @constant
     * @default
     */
    const shadowColor = theme.colors.text,
      bearishColor = tinycolor(getColorForTheme(risecolor, darkTheme)).toString(),
      bullishColor = tinycolor(getColorForTheme(fallcolor, darkTheme)).toString(),
      gap = 2,
      bodyWidthFactor = 0.5,
      shadowWidth = 0,
      bodyOutline = 0;
    u.ctx.save();

    /**
     * @constant
     * @default
     */
    const offset = (shadowWidth % 2) / 2;
    u.ctx.translate(offset, offset);
    for (let i = 0; i < u.scales.x.max!; i++) {
      let open = u.data[1][i];
      let high: any = u.data[2][i];
      let low: any = u.data[3][i];
      let close = u.data[4][i];
      /**
       * u.valToPos function gives pixel value in canvas according to value
       */
      let timeAsX = u.valToPos(i, 'x', true);
      let openAsY = u.valToPos(open!, u.series[1].scale!, true);
      let highAsY = u.valToPos(high!, u.series[1].scale!, true);
      let lowAsY = u.valToPos(low!, u.series[1].scale!, true);
      let closeAsY = u.valToPos(close!, u.series[1].scale!, true);

      let timeAsX1 = Math.round(u.valToPos(i + 1, 'x', true));
      let high1: any = u.data[2][i + 1];
      let low1: any = u.data[3][i + 1];

      let highAsY1: any = Math.round(u.valToPos(high1!, u.series[1].scale!, true));
      let lowAsY1: any = Math.round(u.valToPos(low1!, u.series[1].scale!, true));

      let highPrev: any = u.data[2][i];
      let lowPrev: any = u.data[3][i];

      if (i > 0) {
        highPrev = u.data[2][i - 1];
        lowPrev = u.data[3][i - 1];
      }

      //* to decide the color of the bars
      let bodyColor = high1! > high! ? bearishColor : bullishColor;
      if (i === 0) {
        bodyColor = high > 0 ? bearishColor : bullishColor;
      } else if (high > highPrev) {
        bodyColor = bearishColor;
      } else if (lowPrev === low && high !== highPrev) {
        bodyColor = bearishColor;
      } else if ((highPrev === high && lowPrev !== low) || (highPrev > high && lowPrev > low)) {
        bodyColor = bullishColor;
      } else if (highPrev === high && lowPrev === low) {
        bodyColor = bearishColor;
      }

      if (opts.total) {
        let highOrigin: any = u.data[2][0];
        let highDest: any = u.data[2][u.data[2].length - 1];
        if (high === highDest) {
          bodyColor = highDest > highOrigin ? bearishColor : bullishColor;
        }
      }

      //* to draw line between bars
      if (opts.barlineSwitch) {
        if (i < u.scales.x.max!) {
          u.ctx.beginPath();
          // u.ctx.setLineDash([4, 4]);
          u.ctx.lineWidth = 1;
          // color
          u.ctx.strokeStyle = bearishColor;
          if (high1 === high && high1 !== 0 && low1 !== 0) {
            u.ctx.moveTo(timeAsX, highAsY);
            u.ctx.lineTo(timeAsX1, highAsY);
          } else if (low1 === low) {
            u.ctx.moveTo(timeAsX, lowAsY);
            u.ctx.lineTo(timeAsX1, lowAsY1);
          } else if (high === low1) {
            u.ctx.moveTo(timeAsX, highAsY);
            u.ctx.lineTo(timeAsX1, lowAsY1);
          } else if (low === high1) {
            u.ctx.moveTo(timeAsX, highAsY1);
            u.ctx.lineTo(timeAsX1, highAsY1);
          } else if (high === high1 && low1 === 0 && low1 !== low) {
            u.ctx.moveTo(timeAsX, highAsY);
            u.ctx.lineTo(timeAsX1, highAsY);
          }

          u.ctx.stroke();
        }
      }
      // shadow rect
      let shadowHeight = Math.max(highAsY, lowAsY) - Math.min(highAsY, lowAsY);
      let shadowX = timeAsX - shadowWidth / 2;
      let shadowY = Math.min(highAsY, lowAsY);

      u.ctx.fillStyle = shadowColor;
      u.ctx.fillRect(Math.round(shadowX), Math.round(shadowY), Math.round(shadowWidth), Math.round(shadowHeight));

      // body rect
      let columnWidth = u.bbox.width / u.scales.x.max! - u.scales.x.min!;
      let bodyWidth = Math.round(bodyWidthFactor * (columnWidth - gap)); // columnWidth;
      let bodyHeight = Math.max(closeAsY, openAsY) - Math.min(closeAsY, openAsY);
      let bodyX = timeAsX - bodyWidth / 2;
      let bodyY = Math.min(closeAsY, openAsY);
      /**
       * tooltip hover interaction quadtree.
       * adding tooltip related data to quad tree.
       * this will gives us that tooltip is present in cursor vicinity or not
       */
      qt.add({
        x: timeAsX - u.bbox.left - bodyWidth / 2,
        y: shadowY - u.bbox.top,
        w: bodyWidth,
        h: shadowHeight,
        sidx: 1,
        didx: i,
      });
      u.ctx.fillStyle = high1! > high! ? bearishColor : bullishColor;
      u.ctx.fillRect(Math.round(bodyX), Math.round(bodyY), Math.round(bodyWidth), Math.round(bodyHeight));

      u.ctx.fillStyle = bodyColor;
      u.ctx.fillRect(
        Math.round(bodyX + bodyOutline),
        Math.round(bodyY + bodyOutline),
        Math.round(bodyWidth - bodyOutline * 2),
        Math.round(bodyHeight - bodyOutline * 2)
      );
      if (high !== undefined && opts.labelSwitch) {
        // to draw labels on bar
        let fontSize = VALUE_MAX_FONT_SIZE;
        let labelOffset = LABEL_OFFSET_MAX_VT;
        let riseOrFall = high - low;
        let dec: any = dataAll?.frames[0]?.fields[1].config.decimals ?? 0;
        let unit: any = dataAll?.frames[0]?.fields[1].config.unit ?? '';
        const formatFunc = getValueFormat(unit || 'none');
        const formattedValue = formatFunc(riseOrFall, dec, null);
        let text = `${formattedValue.prefix ?? ''}${formattedValue.text}${formattedValue.suffix ?? ''}`;
        // let text = `${riseOrFall.toFixed(dec)} ${unit}`;
        vSpace = u.bbox.height / 16;
        hSpace = bodyWidth;
        fontSize = Math.round(Math.min(fontSize, VALUE_MAX_FONT_SIZE, calculateFontSize(text, hSpace, vSpace, 1)));
        if (fontSize > VALUE_MIN_FONT_SIZE) {
          u.ctx.fillStyle = theme.colors.text;
          u.ctx.font = `${fontSize}px Roboto`;
          let x1 = timeAsX - u.bbox.left - bodyWidth / 2;
          // let y1 = shadowY,
          let w1 = bodyWidth;
          // h1 = u.bbox.height - u.bbox.top;
          let align: CanvasTextAlign = 'center';
          let baseline: CanvasTextBaseline = high < 0 ? 'top' : 'alphabetic';
          u.ctx.textAlign = align;
          u.ctx.textBaseline = baseline;
          // let middleShift = 0;
          u.ctx.fillText(
            text,
            u.bbox.left + (x1 + w1 / 2),
            bodyHeight > 50 ? shadowY + shadowHeight / 2 : shadowY - labelOffset
          );
        }
      }
    }

    u.ctx.translate(-offset, -offset);

    u.ctx.restore();
  };

  /**
   * bars with lines and without lines are made here for waterfall chart
   * when waterfall chart is selected with type time.
   */
  const drawWaterfallWithTime = (u: uPlot) => {
    /**
     * @constant
     * @default
     */

    /**
     * colors for the bars when the bar is rising is stored in bearishColor var
     * colors for the bars when the bar is falling is stored in bullishColor var
     */
    const shadowColor = theme.colors.text,
      bearishColor = tinycolor(getColorForTheme(risecolor, darkTheme)).toString(),
      bullishColor = tinycolor(getColorForTheme(fallcolor, darkTheme)).toString(),
      shadowWidth = 0,
      bodyOutline = 0;
    u.ctx.save();

    let [idx0, idx1] = u.series[0].idxs!;
    /**
     * @constant
     * @default
     */
    const offset = (shadowWidth % 2) / 2;
    u.ctx.translate(offset, offset);
    for (let i = idx0; i <= idx1; i++) {
      let xVal = u.data[0][i];
      let open = u.data[1][i];
      let high: any = u.data[2][i];
      let low: any = u.data[3][i];
      let close = u.data[4][i];

      /**
       * u.valToPos function gives pixel value in canvas according to value
       */
      let timeAsX = u.valToPos(xVal, 'x', true);
      let openAsY = u.valToPos(open!, u.series[1].scale!, true);
      let highAsY = u.valToPos(high!, u.series[1].scale!, true);
      let lowAsY = u.valToPos(low!, u.series[1].scale!, true);
      let closeAsY = u.valToPos(close!, u.series[1].scale!, true);

      let timeAsX1 = Math.round(u.valToPos(u.data[0][i + 1], 'x', true));
      let high1: any = u.data[2][i + 1];
      let low1: any = u.data[3][i + 1];

      let highAsY1: any = Math.round(u.valToPos(high1!, u.series[1].scale!, true));
      let lowAsY1: any = Math.round(u.valToPos(low1!, u.series[1].scale!, true));

      let highPrev: any = u.data[2][i];
      let lowPrev: any = u.data[3][i];

      if (i > 0) {
        highPrev = u.data[2][i - 1];
        lowPrev = u.data[3][i - 1];
      }

      //* to decide the color of the bars
      let bodyColor = high1! > high! ? bearishColor : bullishColor;
      if (i === 0) {
        bodyColor = high > 0 ? bearishColor : bullishColor;
      } else if (high > highPrev) {
        bodyColor = bearishColor;
      } else if (lowPrev === low && high !== highPrev) {
        bodyColor = bearishColor;
      } else if ((highPrev === high && lowPrev !== low) || (highPrev > high && lowPrev > low)) {
        bodyColor = bullishColor;
      } else if (highPrev === high && lowPrev === low) {
        bodyColor = bearishColor;
      }

      if (opts.total) {
        let highOrigin: any = u.data[2][0];
        let highDest: any = u.data[2][u.data[2].length - 1];
        if (high === highDest) {
          bodyColor = highDest > highOrigin ? bearishColor : bullishColor;
        }
      }

      //* to draw line between bars
      if (opts.barlineSwitch) {
        if (i < idx1) {
          u.ctx.beginPath();
          // u.ctx.setLineDash([4, 4]);
          u.ctx.lineWidth = 1;
          // color
          u.ctx.strokeStyle = bearishColor;

          if (high1 === high && high1 !== 0 && low1 !== 0) {
            u.ctx.moveTo(timeAsX, highAsY);
            u.ctx.lineTo(timeAsX1, highAsY);
          } else if (low1 === low) {
            u.ctx.moveTo(timeAsX, lowAsY);
            u.ctx.lineTo(timeAsX1, lowAsY1);
          } else if (high === low1) {
            u.ctx.moveTo(timeAsX, highAsY);
            u.ctx.lineTo(timeAsX1, lowAsY1);
          } else if (low === high1) {
            u.ctx.moveTo(timeAsX, highAsY1);
            u.ctx.lineTo(timeAsX1, highAsY1);
          } else if (high === high1 && low1 === 0 && low1 !== low) {
            u.ctx.moveTo(timeAsX, highAsY);
            u.ctx.lineTo(timeAsX1, highAsY);
          }

          u.ctx.stroke();
        }
      }
      // shadow rect
      let shadowHeight = Math.max(highAsY, lowAsY) - Math.min(highAsY, lowAsY);
      let shadowX = timeAsX - shadowWidth / 2;
      let shadowY = Math.min(highAsY, lowAsY);

      u.ctx.fillStyle = shadowColor;
      u.ctx.fillRect(Math.round(shadowX), Math.round(shadowY), Math.round(shadowWidth), Math.round(shadowHeight));

      // body rect
      let columnWidth = u.bbox.width / ((idx1 - idx0) * 2);
      let bodyWidth = columnWidth;
      let bodyHeight = Math.max(closeAsY, openAsY) - Math.min(closeAsY, openAsY);
      let bodyX = timeAsX - bodyWidth / 2;
      let bodyY = Math.min(closeAsY, openAsY);
      /**
       * tooltip hover interaction quadtree.
       * adding tooltip related data to quad tree.
       * this will gives us that tooltip is present in cursor vicinity or not
       */
      qt.add({
        x: timeAsX - u.bbox.left - bodyWidth / 2,
        y: shadowY - u.bbox.top,
        w: bodyWidth,
        h: shadowHeight,
        sidx: 1,
        didx: i,
      });

      u.ctx.fillStyle = high1! > high! ? bearishColor : bullishColor;
      u.ctx.fillRect(Math.round(bodyX), Math.round(bodyY), Math.round(bodyWidth), Math.round(bodyHeight));

      u.ctx.fillStyle = bodyColor;
      u.ctx.fillRect(
        Math.round(bodyX + bodyOutline),
        Math.round(bodyY + bodyOutline),
        Math.round(bodyWidth - bodyOutline * 2),
        Math.round(bodyHeight - bodyOutline * 2)
      );
      if (high !== undefined && opts.labelSwitch) {
        // to draw labels on bar
        let fontSize = VALUE_MAX_FONT_SIZE;
        let labelOffset = LABEL_OFFSET_MAX_VT;
        let riseOrFall = high - low;
        let dec: any = dataAll?.frames[0]?.fields[1].config.decimals ?? 0;
        let unit: any = dataAll?.frames[0]?.fields[1].config.unit ?? '';
        const formatFunc = getValueFormat(unit || 'none');
        const formattedValue = formatFunc(riseOrFall, dec, null);
        let text = `${formattedValue.prefix ?? ''}${formattedValue.text}${formattedValue.suffix ?? ''}`;
        // let text = `${riseOrFall.toFixed(dec)} ${unit}`;
        vSpace = u.bbox.height / 7;
        hSpace = bodyWidth;
        fontSize = Math.round(Math.min(fontSize, VALUE_MAX_FONT_SIZE, calculateFontSize(text, hSpace, vSpace, 1)));
        if (fontSize > VALUE_MIN_FONT_SIZE) {
          u.ctx.fillStyle = theme.colors.text;
          u.ctx.font = `${fontSize}px Roboto`;
          let x1 = timeAsX - u.bbox.left - bodyWidth / 2;
          // let y1 = shadowY,
          let w1 = bodyWidth;
          // h1 = u.bbox.height - u.bbox.top;
          let align: CanvasTextAlign = 'center';
          let baseline: CanvasTextBaseline = high < 0 ? 'top' : 'alphabetic';
          u.ctx.textAlign = align;
          u.ctx.textBaseline = baseline;
          // let middleShift = 0;
          u.ctx.fillText(
            text,
            u.bbox.left + (x1 + w1 / 2),
            bodyHeight > 50 ? shadowY + shadowHeight / 2 : shadowY - labelOffset
            // u.bbox.top + high < 0 ? y1 + h1 + labelOffset : y1 + h1 / 2 - labelOffset
          );
        }
      }
    }

    u.ctx.translate(-offset, -offset);

    u.ctx.restore();
  };

  /**
   * custom designed x values function
   * @function
   * @param {uPlot} u
   * @param {number[]} val
   * @returns {Array<string>} x axis data
   */
  const xValuesCandleTime: Axis.Values = (u: uPlot, val) => {
    let xDataTime = [];
    for (let index = 0; index < dataAll.waterfall?.time.values.length; index++) {
      const incrNew = clusterSwitch ? customClusterSize * 60 * 1e3 : toCluster * 60 * 1e3;

      const time = formatTime(dataAll.waterfall?.time.values[index], incrNew);
      xDataTime.push(time);
    }

    return xDataTime;
  };

  /**
   * custom designed x values function
   * @function
   * @param {uPlot} u
   * @param {number[]} val
   * @returns {Array<string>} x axis data
   */
  const xValuesCandle: Axis.Values = (u: uPlot, val) => {
    let xData = [];
    xData.push('');
    let valtype: number | string = typeof dataAll.waterfall?.time.values[0];

    if (valtype === 'number') {
      dataAll.waterfall?.time.values.map((item: number) => {
        let tim = new Date(item);
        xData.push(tim.getFullYear());
      });
    } else {
      for (let index = 0; index < dataAll.waterfall?.time.values.length; index++) {
        const name = dataAll.waterfall?.time.values[index];
        xData.push(name ?? '');
      }
      xData.push('');
    }
    return xData;
  };

  /**
   * custom designed x values function
   * @function
   * @returns {Array<DateTime>} x axis data
   */
  const xTimeCandle: any = () => {
    let xData = [];
    xData.push('');
    for (let index = 0; index < dataAll.waterfall?.time.values.length; index++) {
      let d = dataAll.waterfall?.time.values[index];
      d = dateTimeParse(d as number, { timeZone: opts.timeZone })
        .toDate()
        .toLocaleDateString(undefined, localeOptions);
      xData.push(d ?? '');
    }
    xData.push('');
    return xData;
  };

  const drawClear = (u: uPlot) => {
    qt = qt || new Quadtree(0, 0, u.bbox.width, u.bbox.height);

    qt.clear();

    //* clear the path cache to force drawBox() to rebuild new quadtree
    u.series.forEach((s) => {
      // @ts-ignore
      s._paths = null;
    });
  };
  const tooltipMaker: TooltipInterface = (seriesIndex, datapointIndex, showTooltip, u) => {
    let found: Rect | undefined;
    let cx = u.cursor.left! * pxRatio;
    let cy = u.cursor.top! * pxRatio;
    qt.get(cx, cy, 1, 1, (o) => {
      if (pointWithin(cx, cy, o.x, o.y, o.x + o.w, o.y + o.h)) {
        found = o;
      }
    });
    if (found) {
      // prettier-ignore
      if (found !== hovered) {
        /* eslint-disable no-multi-spaces */
        barMark.style.display = '';
        barMark.style.left   = found!.x / pxRatio + 'px';
        barMark.style.top    = found!.y / pxRatio + 'px';
        barMark.style.width  = found!.w / pxRatio + 'px';
        barMark.style.height = found!.h / pxRatio + 'px';
        hovered = found;
        seriesIndex(hovered.sidx);
        datapointIndex(hovered.didx);
        showTooltip();
        /* eslint-enable */
      }
    } else if (hovered !== undefined) {
      seriesIndex(hovered!.sidx);
      datapointIndex(hovered!.didx);
      showTooltip();
      hovered = undefined;
      barMark.style.display = 'none';
    } else {
      showTooltip(true);
    }
  };

  return {
    // cursor & select opts
    cursor,
    select,

    // scale & axis opts
    xValuesCandleTime,
    xValuesCandle,
    xTimeCandle,

    // pathbuilders
    // drawBars,
    // drawPoints,

    // hooks
    init,
    // drawBoxes,
    drawWaterfallWithTime,
    drawWaterfall,
    drawClear,
    // setCursor,
    tooltipMaker,
  };
}
