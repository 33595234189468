import React, { useState, useEffect } from 'react';
import { Select } from '@grafana/ui';
import { loadS3Images } from 'app/features/imageuploader/image';
import { SelectableValue } from '@grafana/data';

interface Props {
  toShowImg: string;
  onChange: (toShowImg: string) => void;
}

export function ImageViewer(props: Props) {
  const [availableImg, setAvailableImg] = useState<SelectableValue[]>([]);

  useEffect(() => {
    (async () => {
      const { images, error } = await loadS3Images();
      if (error === null) {
        setAvailableImg(images);
      } else {
        console.log(error);
      }
    })();
  }, []);

  const onImageChange = ({ value }: any) => {
    props.onChange(value);
  };
  return (
    <div className={`width-30 image-panel`}>
      <div style={{ display: 'flex' }}>
        <label className="gf-form-label width-7">Available Images</label>
        <Select
          width={25}
          options={availableImg}
          // defaultValue={availableImg[0]}
          onChange={onImageChange}
          value={availableImg.find((item: any) => item.value === props.toShowImg)}
        />
      </div>
    </div>
  );
}
