import React, { useEffect, useState } from 'react';
import { DashboardModel, PanelModel } from '../../../state';
import {
  Button,
  ClipboardButton,
  Field,
  FieldSet,
  Icon,
  Input,
  Label,
  Select,
  Switch,
  TimeRangeInput,
  VerticalGroup,
  useStyles,
} from '@grafana/ui';
import { Layout } from '@grafana/ui/src/components/Layout/Layout';
import { AppEvents, GrafanaTheme, SelectableValue } from '@grafana/data';
import { css } from '@emotion/css';
import appEvents from 'app/core/app_events';
import { config } from 'app/core/config';
import { getBackendSrv } from '@grafana/runtime';
import { getTimeRange } from 'app/core/utils/explore';

const expireOptions = [
  { label: '5 Minutes', value: 5 * 60 },
  { label: '15 Minutes', value: 15 * 60 },
  { label: '1 Hour', value: 60 * 60 },
  { label: '6 Hours', value: 6 * 60 * 60 },
  { label: '12 Hours', value: 12 * 60 * 60 },
  { label: '1 Day', value: 24 * 60 * 60 },
  { label: '3 Days', value: 3 * 24 * 60 * 60 },
];

export interface Props {
  dashboard: DashboardModel;
  panel?: PanelModel;
  isActive: boolean;
  openDashboard: any;
  setIsActive: (e: boolean) => void;
  setOpenDashboard: () => void;
}

/**
 * Functional component for Open Dashboard Modal.
 * @param {Props} props - The props for the component.
 */
const OpenDashboardModal = (props: Props) => {
  const [viewLinkPage, setViewLinkPage] = useState<boolean>(false);
  const [expireTime, setExpireTime] = useState<SelectableValue<number>>(expireOptions[5]);
  const [timeRangeAccess, setTimeRangeAccess] = useState<boolean>(false);
  const [showAnnotations, setShowAnnotations] = useState<boolean>(false);
  const [pauseOpenDashboard, setpauseOpenDashboard] = useState<boolean>(false);
  const [openDashboardURL, setOpenDashboardURL] = useState<string>('');
  const [openDashboard, setOpenDashboard] = useState<null | any>(null);

  const styles = useStyles(getStyles);

  const timeRange: any = getTimeRange(props.dashboard.getTimezone(), props.dashboard.getDefaultTime());

  /**
   * Function to retrieve Open Dashboard data.
   */
  const getOpenDashboard = async () => {
    const data = await getBackendSrv().post('/opendashboard/getByDashId', { DashboardId: props.dashboard.uid });
    if (data.result) {
      const openDashboard = data.result.find((ele: any) => ele.dashboard_id === props.dashboard.uid);
      setOpenDashboardURL(`${config.carbonChatBaseUrl}/open-dashboard/${openDashboard.oid}`);
      setTimeRangeAccess(openDashboard.time_range_picker);
      setShowAnnotations(openDashboard.annotations);
      setExpireTime(openDashboard.expiry);
      setpauseOpenDashboard(!openDashboard.is_open);
      setViewLinkPage(true);
      setOpenDashboard(openDashboard);
    } else {
      setOpenDashboard(null);
      setViewLinkPage(false);
    }
  };

  /**
   * Function to generate a unique string.
   * @returns {string} - A unique string.
   */
  const generateUniqueString = () => {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var uniqueString = '';

    for (var i = 0; i < 32; i++) {
      var randomIndex = Math.floor(Math.random() * chars.length);
      uniqueString += chars.charAt(randomIndex);
    }

    return uniqueString;
  };

  /**
   * Function to generate an Open Dashboard URL and add it to the database.
   */
  const generateOpenUrl = async () => {
    setViewLinkPage(true);
    const uniqueOpenDashboardId = generateUniqueString();
    const url = `${config.carbonChatBaseUrl}/open-dashboard/${uniqueOpenDashboardId}`;
    setOpenDashboardURL(url);

    try {
      await getBackendSrv().post('/opendashboard/add', {
        oid: uniqueOpenDashboardId,
        dashboardId: props.dashboard.uid,
        dashboardName: props.dashboard.title,
        createdBy: props.dashboard.meta.createdBy,
        expiry: expireTime.value,
        orgid: config.bootData.user.orgId,
        userId: config.bootData.user.id,
      });
      props.setIsActive(true);
      props.setOpenDashboard();
      await getOpenDashboard();
      // appEvents.emit(AppEvents.alertSuccess, ['Open Dashboard Created']);
    } catch (err) {
      appEvents.emit(AppEvents.alertError, ['Unable to add create a record for the open dashboard']);
      console.log(err);
    }
  };

  /**
   * Function to handle the change of time range access.
   * @param {boolean} value - The new time range access value.
   */
  const onChangeTimeRangeAccess = async (value: boolean) => {
    try {
      setTimeRangeAccess(value);
      const response = await getBackendSrv().put('/opendashboard/update', {
        id: openDashboard.id,
        time_range_picker: value,
      });
      if (response.result) {
        props.setOpenDashboard();
        appEvents.emit(AppEvents.alertSuccess, ['Open Dashboard Updated']);
      }
    } catch (err) {
      console.log(err);
      appEvents.emit(AppEvents.alertError, ['Unable to update open dashboard']);
    }
  };

  /**
   * Function to handle the change of annotations access.
   * @param {boolean} value - The new annotations access value.
   */
  const onAnnotationsAccessChange = async (value: boolean) => {
    try {
      setShowAnnotations(value);
      const response = await getBackendSrv().put('/opendashboard/update', {
        id: openDashboard.id,
        annotations: value,
      });
      if (response.result) {
        props.setOpenDashboard();
        appEvents.emit(AppEvents.alertSuccess, ['Open Dashboard Updated']);
      }
    } catch (err) {
      console.log(err);
      appEvents.emit(AppEvents.alertError, ['Unable to update open dashboard']);
    }
  };

  /**
   * Function to handle the change of pause Open Dashboard.
   * @param {boolean} value - The new pause Open Dashboard value.
   */
  const pauseOpenDashboardChange = async (value: boolean) => {
    setpauseOpenDashboard(value);
    try {
      const response = await getBackendSrv().put('/opendashboard/update', {
        id: openDashboard.id,
        is_open: !value,
      });
      if (response.result) {
        props.setOpenDashboard();
        appEvents.emit(AppEvents.alertSuccess, ['Open Dashboard Updated']);
      }
    } catch (err) {
      console.log(err);
      appEvents.emit(AppEvents.alertError, ['Unable to update open dashboard']);
    }
  };

  /**
   * Function to handle the change of expiry.
   * @param {SelectableValue<number>} options - The new expiry options.
   */
  const onExpiryChange = async (options: SelectableValue<number>) => {
    try {
      setExpireTime(options);
      const response = await getBackendSrv().put('/opendashboard/update', {
        id: openDashboard.id,
        expiry: options.value,
      });
      if (response.result) {
        props.setOpenDashboard();
        await cacheReseting(openDashboard.oid);
        appEvents.emit(AppEvents.alertSuccess, ['Open Dashboard Updated']);
      }
    } catch (err) {
      console.log(err);
      appEvents.emit(AppEvents.alertError, ['Unable to update open dashboard']);
    }
  };
  /**
   * Function to reset the cache for an Open Dashboard.
   * @param {string} openID - The ID of the Open Dashboard.
   */
  const cacheReseting = async (openID: string) => {
    await getBackendSrv().delete(`/open/cache/${openID}`);
  };

  /**
   * Function to delete an Open Dashboard.
   */
  const deleteDashboard = async () => {
    try {
      const response = await getBackendSrv().put('/opendashboard/delete', { id: openDashboard.id });
      if (response.result) {
        props.setIsActive(false);
        props.setOpenDashboard();
        await getOpenDashboard();
        appEvents.emit(AppEvents.alertSuccess, ['Open Dashboard Deleted']);
      }
    } catch (err) {
      console.log(err);
      appEvents.emit(AppEvents.alertError, ['Unable to delete open dashboard']);
    }
  };

  /**
   * Function to render the Open Dashboard components.
   * @returns {JSX.Element} - The JSX element representing the Open Dashboard components.
   */
  const openDashboardComp = () => {
    return (
      <>
        <FieldSet>
          <VerticalGroup spacing="md">
            <Layout orientation={1} spacing="xs" justify="space-between">
              <Label description="The open dashboard uses the default time range settings of the dashboard">
                Default time range
              </Label>
              <TimeRangeInput value={timeRange} onChange={() => {}} disabled />
            </Layout>
            <Layout orientation={0} spacing="sm">
              <Switch
                value={timeRangeAccess}
                onChange={(e: any) => {
                  onChangeTimeRangeAccess(e.currentTarget.checked);
                }}
              />
              <Label description="Allow viewers to change time range">Time range picker enabled</Label>
            </Layout>
            <Layout orientation={0} spacing="sm">
              <Switch
                value={showAnnotations}
                onChange={(e: any) => {
                  onAnnotationsAccessChange(e.currentTarget.checked);
                }}
              />
              <Label description="Show annotations on open dashboard">Show annotations</Label>
            </Layout>
          </VerticalGroup>
          <Field label="Cache Timeout">
            <Select
              width={30}
              maxMenuHeight={170}
              options={expireOptions}
              value={expireTime}
              onChange={onExpiryChange}
            />
          </Field>
        </FieldSet>
        <Field label="Open Dashboard URL">
          <Input
            type="text"
            value={openDashboardURL}
            addonAfter={
              <ClipboardButton
                variant="primary"
                getText={() => openDashboardURL}
                onClipboardCopy={() => appEvents.emit(AppEvents.alertSuccess, ['Content copied to clipboard'])}
              >
                <Icon name="copy" />
                Copy
              </ClipboardButton>
            }
            readOnly
          />
        </Field>
        <div className={styles.bottomContainer}>
          <Layout orientation={0}>
            <Switch
              value={pauseOpenDashboard}
              onChange={(e: any) => {
                pauseOpenDashboardChange(e.currentTarget.checked);
              }}
            />
            <Label>Pause Sharing Dashboard</Label>
          </Layout>
          <Button variant="destructive" onClick={deleteDashboard}>
            Delete Open Dashboard
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    getOpenDashboard();
  }, []);

  return (
    <>
      {!viewLinkPage ? (
        <div>
          <h4>Open Dashboard can be viewed by anyone with a link</h4>
          <h4>Viewer can view all the panels, annotations and set time range</h4>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={generateOpenUrl}>Generate Link</Button>
          </div>
        </div>
      ) : (
        openDashboardComp()
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  bottomContainer: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  `,
});

export default OpenDashboardModal;
