import { getBackendSrv } from '@grafana/runtime';

export const getCarbonPushData = async (panelId: number, dashboardId: number) => {
  try {
    const userinfo = await getBackendSrv().get('/api/user');
    const signedUserInfo: any = {
      userid: userinfo.id,
      useremail: userinfo.email,
      panelid: panelId,
      dashboardid: dashboardId,
    };
    const res = await getBackendSrv().post('/api/push/getdata', signedUserInfo);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getCarbonPullData = async (panelId: number, dashboardId: number) => {
  try {
    const userinfo = await getBackendSrv().get('/api/user');
    const signedUserInfo: any = {
      userid: userinfo.id,
      useremail: userinfo.email,
      panelid: panelId,
      dashboardid: dashboardId,
    };
    const res = await getBackendSrv().post('/api/export/getdata', signedUserInfo);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteData = async (pushData: any, pullData: any, alerts: any) => {
  try {
    if (pushData !== null) {
      var mainId: string[] = [];
      for (let i = 0; i < pushData.length; i++) {
        mainId.push(pushData[i].mainId);
      }
      const userinfo = await getBackendSrv().get('/api/user');
      const signedUserInfo: any = {
        userid: userinfo.id,
        useremail: userinfo.email,
        mainId: mainId,
      };
      await getBackendSrv().post('/api/push/delete', signedUserInfo);
    }
    if (pullData !== null) {
      var key: string[] = [];
      for (let i = 0; i < pullData.length; i++) {
        key.push(pullData[i].key);
      }
      await getBackendSrv().post('/api/export/multidelete', { key: key });
    }
    if (alerts !== null) {
      for (let i = 0; i < alerts.length; i++) {
        const element = alerts[i];
        await getBackendSrv().delete(`/api/alert-definitions/${element.uid}`);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAlertRuleList = async (panel: any, dashboard: any) => {
  const ngAlertDefinitions = await getBackendSrv().get(`/api/alert-definitions/${dashboard.uid}/${panel.id}`);

  return ngAlertDefinitions;
};
