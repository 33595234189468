import { ColorPicker, Field, InlineField, InlineLabel, Input, Select, Slider, Switch } from '@grafana/ui';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useObservable } from 'react-use';
import { getIconOptions } from '../custom/CustomSymbol';
import { OSMapLayerState } from '../types/types';
import { LayerContentInfo, geojsonSource } from './type';

/**
 * Render style configuration options for a GeoJSON layer.
 *
 * @param {Props} props - The component's properties.
 * @param {OSMapLayerState<any>} props.layer - The GeoJSON layer state.
 * @param {Observable<LayerContentInfo | undefined>} props.layerInfoObj - An observable containing layer content information.
 * @returns {JSX.Element | null} - The rendered style configuration options or null if layer information is not available.
 */
interface Props {
  layer: OSMapLayerState<any>;
  layerInfoObj: any;
}
export const LABEL_WIDTH = 12;

/**
 * Render the style configuration for a GeoJSON layer.
 * @param {Props} props - The component's props.
 * @param {OSMapLayerState<any>} props.layer - The layer state.
 * @param {Observable<LayerContentInfo | undefined>} props.layerInfoObj - An observable containing layer content information.
 * @returns {React.ReactNode | null} The rendered GeoJSON style configuration.
 */
export function GeojsonStyle({ layer, layerInfoObj }: Props) {
  /**
   * Get the layer content information from the observable.
   * @type {LayerContentInfo | undefined}
   */
  const layerInfo: LayerContentInfo | undefined = useObservable(layerInfoObj);
  if (!layerInfo) {
    return null;
  }
  return (
    <Field label="Geojson style">
      <>
        <InlineField label="Layer Opacity" labelWidth={LABEL_WIDTH}>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={layer.options.opacity || 1}
            onChange={(v) => onchangeLayerOption(v, 'opacity', layer)}
          />
        </InlineField>
        <InlineField label={'Source'} labelWidth={LABEL_WIDTH} tooltip={'Select predefined geojson data or paste url'}>
          <Select
            options={geojsonSource}
            onChange={(e) => onchangeLayerOption(e.value, 'config.src', layer)}
            value={layer.options.config.src}
            allowCustomValue
          />
        </InlineField>

        {layerInfo.geometryType === 'point' && (
          <>
            <InlineField label="Icon" labelWidth={LABEL_WIDTH} grow={true}>
              <Select
                menuShouldPortal
                placeholder={'Circle'}
                value={layer.options.config.style.symbol || 'circle'}
                options={getIconOptions}
                onChange={(e) => onchangeLayerOption(e.value, 'config.style.symbol', layer)}
                aria-label={'icon select'}
              />
            </InlineField>
            <InlineField label="Icon Size" labelWidth={LABEL_WIDTH} grow={true}>
              <Input
                type="number"
                value={layer.options.config.style.size || 3}
                placeholder="numeric value"
                onChange={(e) => onchangeLayerOption(Number(e.currentTarget.value), 'config.style.size', layer)}
              />
            </InlineField>
            <InlineField label="Rotation" labelWidth={LABEL_WIDTH} grow={true}>
              <Input
                min={0}
                max={360}
                type="number"
                value={layer.options.config.style.rotation || 0}
                placeholder="numeric value"
                onChange={(e) => onchangeLayerOption(Number(e.currentTarget.value), 'config.style.rotation', layer)}
              />
            </InlineField>
          </>
        )}
        <InlineField label="Color" labelWidth={LABEL_WIDTH}>
          <InlineLabel width={4}>
            <ColorPicker
              color={layer.options.config.style.color || 'dark-red'}
              onChange={(color) => onchangeLayerOption(color, 'config.style.color', layer)}
              enableNamedColors={true}
            />
          </InlineLabel>
        </InlineField>
        <InlineField label="Fil Opacity" labelWidth={LABEL_WIDTH}>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={layer.options.config.style.opacity || 1}
            onChange={(v) => onchangeLayerOption(v, 'config.style.opacity', layer)}
          />
        </InlineField>
        <InlineField label="Show Tooltip" style={{ alignItems: 'center' }} labelWidth={LABEL_WIDTH}>
          <Switch
            value={layer.options.tooltip}
            onChange={(e) => onchangeLayerOption(e.currentTarget.checked, 'tooltip', layer)}
          />
        </InlineField>
      </>
    </Field>
  );
}

/**
 * Update a specific option within a layer's configuration and trigger a change event.
 *
 * @param {any} value - The new value for the option.
 * @param {string} path - The path to the option within the configuration.
 * @param {OSMapLayerState<any>} layer - The layer to update.
 */
export const onchangeLayerOption = (value: any, path: string, layer: OSMapLayerState<any>) => {
  const newOpt = setProperty(cloneDeep(layer.options), path, value);
  layer.onChange(newOpt);
};

/**
 * Set the value of a property within an object using a dot-separated path.
 *
 * @param {any} obj - The object to update.
 * @param {string} path - The dot-separated path to the property.
 * @param {any} value - The new value to set.
 * @returns {any} - The modified object.
 */
export const setProperty = (obj: any, path: string, value: any): any => {
  const [head, ...rest] = path.split('.');

  return {
    ...obj,
    [head]: rest.length ? setProperty(obj[head], rest.join('.'), value) : value,
  };
};
