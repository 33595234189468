import { DataFrame } from '@grafana/data';
import { covertToRequiredDateString } from './toCsv';

/**
 * Generates a unique string consisting of alphanumeric characters.
 * @returns {string} The generated unique string.
 */
export function generateUniqueString() {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var uniqueString = '';

  for (var i = 0; i < 8; i++) {
    var randomIndex = Math.floor(Math.random() * chars.length);
    uniqueString += chars.charAt(randomIndex);
  }

  return uniqueString;
}

/**
 * Converts a zip file to a Base64 string.
 * @param {File} zipFile - The zip file to convert.
 * @returns {Promise<string>} A promise that resolves with the Base64 string representation of the zip file.
 */
export function convertZipToBase64(zipFile: any) {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();

    reader.onload = () => {
      const base64String: any = btoa(reader.result);
      resolve(base64String);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsBinaryString(zipFile);
  });
}

/**
 * Checks if a given string is a valid JSON.
 *
 * @param {string} str - The input string to be checked.
 * @returns {boolean} Returns true if the input string is valid JSON, false otherwise.
 */
export function isValidJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Converts a DataFrame's series to a JSON string.
 *
 * @param {DataFrame} series - The DataFrame containing the series to be converted.
 * @returns {string} Returns the JSON string representation of the series.
 */
export function convertSeriesToJSONString(series: DataFrame) {
  try {
    const jsonArray: any = [];
    const fields = series.fields;
    let timeIndex: any = undefined;
    for (let i = 0; i < fields.length; i++) {
      const ele = fields[i];
      if (ele.type === 'time') {
        timeIndex = i;
      }
    }
    for (let i = 0; i < series.length; i++) {
      const subObj: any = {};
      for (let j = 0; j < fields.length; j++) {
        const value = fields[j].values.get(i);
        if (j === timeIndex && value && typeof value === 'number') {
          const date = new Date(value);
          subObj[fields[j].name] = covertToRequiredDateString(date);
        } else {
          var seriesValue = series.fields[j].values.get(i);
          if (typeof seriesValue === 'string') {
            var lowerCaseValue = seriesValue.toLowerCase();
            if (lowerCaseValue === 'true') {
              seriesValue = true;
            } else if (lowerCaseValue === 'false') {
              seriesValue = false;
            }
          }
          subObj[series.fields[j].name] = seriesValue;
        }
      }
      jsonArray.push(subObj);
    }
    return JSON.stringify(jsonArray);
  } catch (error) {
    console.log(error);
    return '';
  }
}

/**
 * Checks if a specified column in a DataFrame has empty values.
 *
 * @param {string | null} columnName - The name of the column to check for empty values, or null for any column.
 * @param {DataFrame} series - The DataFrame containing the data.
 * @returns {boolean} Returns true if the column has no empty values, false otherwise.
 * @throws {Error} Throws an error if the target field is not a number.
 */
export function checkColumnHasEmptyValues(columnName: null | string, series: DataFrame) {
  const columnField = series.fields.find((ele: any) => ele.name === columnName);
  if (columnField?.type !== 'number') {
    throw new Error('Target Field is not a number');
  } else {
    const targetArray = columnField.values.toArray();
    for (let ele of targetArray) {
      if (!ele) {
        return false;
      }
    }
  }
  return true;
}

/**
 * Removes rows with empty values from a DataFrame.
 *
 * @param {string | null} columnName - The name of the column used for checking empty values, or null for any column.
 * @param {DataFrame} series - The DataFrame containing the data.
 * @returns {boolean} Returns true if rows with empty values are successfully removed, false otherwise.
 */
export function removeRowsWithEmptyValuesFromDataFrame(columnName: null | string, series: DataFrame) {
  console.log('series: ', series);
  const columnField = series.fields.find((ele: any, index: number) => ele.name === columnName);
  if (columnField) {
    const targetArray = columnField.values.toArray();
    console.log('target array: ', targetArray);
    for (let i = 0; i < targetArray?.length; i++) {
      if (targetArray[i] === '' || targetArray[i] === null) {
        series.fields.forEach((ele) => {
          ele.values.toArray().splice(i, 1);
          series.length -= 1;
        });
        i--;
      }
    }
    return true;
  }
  return false;
}

/**
 * Enters a default value in place of empty values in a specified column of a DataFrame.
 *
 * @param {string | null} columnName - The name of the column to replace empty values, or null for any column.
 * @param {DataFrame} series - The DataFrame containing the data.
 * @param {string} value - The default value to be entered in place of empty values.
 * @returns {boolean} Returns true if default values are successfully entered, false otherwise.
 */
export function enterDefaultValueInPlaceOfEmptyValue(columnName: null | string, series: DataFrame, value: string) {
  const columnField = series.fields.find((ele: any) => ele.name === columnName);
  if (columnField) {
    const targetArray = columnField.values.toArray();
    for (let i = 0; i < targetArray?.length; i++) {
      if (targetArray[i] === '' || targetArray[i] === null) {
        targetArray[i] = value;
      }
    }
  }
  return true;
}

/**
 * Calculates the mean value of an array of strings.
 *
 * @param {string[]} targetArray - The array of values for which to calculate the mean.
 * @returns {string} Returns the mean value as a string.
 */
export function calcMean(targetArray: string[]) {
  let sum = 0;
  for (let i = 0; i < targetArray.length; i++) {
    if (targetArray[i] !== '' && targetArray[i] !== null) {
      sum += parseFloat(targetArray[i]);
    }
  }
  return (sum / targetArray.length).toString();
}

/**
 * basic median function that recieves an array parameter and returns the median of that array
 * @param {numer[]} arr - The array of values for which to calculate the median
 * @returns {number} median -  Returns the median value as a number, or undefined if the array is empty.
 */
function median(arr: number[]) {
  if (arr.length === 0) {
    return; // 0.
  }
  arr.sort((a, b) => a - b); // 1.
  const midpoint = Math.floor(arr.length / 2); // 2.
  const median: number =
    arr.length % 2 === 1
      ? arr[midpoint] //If odd length, just take midpoint
      : (arr[midpoint - 1] + arr[midpoint]) / 2; // If even length, take median of midpoints
  return median;
}

/**
 * Calculates the median value of an array of strings.
 *
 * @param {string[]} targetArray - The array of values for which to calculate the median.
 * @returns {string | undefined} Returns the median value as a string, or undefined if the array is empty.
 */
export function calcMedian(targetArray: string[]) {
  const floatTargetArray: number[] = [];
  for (let i = 0; i < targetArray.length; i++) {
    if (targetArray[i] !== '' && targetArray[i] !== null) {
      floatTargetArray.push(parseFloat(targetArray[i]));
    }
  }
  return median(floatTargetArray)?.toString();
}

/**
 * Substitutes empty values with the previous non-empty value in an array of strings.
 *
 * @param {string[]} targetArray - The array of values to process.
 * @param {DataFrame} series - The DataFrame containing the data.
 */
export function substitutePrevious(targetArray: string[], series: DataFrame) {
  for (let i = 0; i < targetArray.length; i++) {
    if (i === 0 && (targetArray[i] === '' || targetArray[i] === null)) {
      series.fields.forEach((ele) => {
        ele.values.toArray().splice(i, 1);
        series.length -= 1;
      });
      i--;
    } else if (targetArray[i] === '' || targetArray[i] === null) {
      targetArray[i] = targetArray[i - 1];
    }
  }
}

/**
 * Adds metric values to a specified column in a DataFrame.
 *
 * @param {string | null} columnName - The name of the column to add metric values, or null for any column.
 * @param {DataFrame} series - The DataFrame containing the data.
 * @param {string} metric - The metric to be added (e.g., 'mean', 'median', 'previous').
 * @returns {boolean} Returns true if metric values are successfully added, false otherwise.
 */
export function addMetricValue(columnName: null | string, series: DataFrame, metric: string) {
  const columnField = series.fields.find((ele: any) => ele.name === columnName);
  if (columnField) {
    const targetArray = columnField.values.toArray();
    let metricVal;
    if (metric === 'mean') {
      metricVal = calcMean(targetArray);
    } else if (metric === 'median') {
      metricVal = calcMedian(targetArray);
    }
    if (metric === 'mean' || metric === 'median') {
      for (let i = 0; i < targetArray?.length; i++) {
        if (targetArray[i] === '' || targetArray[i] === null) {
          targetArray[i] = metricVal;
        }
      }
    } else if (metric === 'previous') {
      substitutePrevious(targetArray, series);
    }
    return true;
  }
  return false;
}
