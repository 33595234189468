import { PanelPlugin } from '@grafana/data';
import { BoxPlotPanel } from './BoxPlotPanel';
import { addStandardDataReduceOptions } from '../stat/types';
// import { addHideFrom } from '../timeseries/config';
import { BoxPlotOptions } from './types';
// import { EditOhlcData } from './waterfallplot/SelectEditor';
import { addLegendOptions } from './config';

export const plugin = new PanelPlugin<BoxPlotOptions>(BoxPlotPanel).useFieldConfig().setPanelOptions((builder) => {
  addStandardDataReduceOptions(builder as any, false);
  builder
    .addRadio({
      path: 'typeWaterfallPlot',
      name: 'Select Waterfall Plot',
      description: 'Select Type Of Waterfall Plot',
      defaultValue: 'query',
      settings: {
        options: [
          { value: 'query', label: 'Query' },
          { value: 'timeseries', label: 'Time-Series' },
        ],
      },
    })
    .addBooleanSwitch({
      path: 'includeTimeField',
      name: 'Include Time Field',
      description: 'Include time in display value',
      defaultValue: false,
    })
    .addColorPicker({
      path: 'risecolor',
      name: 'Rise',
      defaultValue: 'red',
    })
    .addColorPicker({
      path: 'fallcolor',
      name: 'Fall',
      defaultValue: 'green',
    })
    .addBooleanSwitch({
      path: 'endyearSwitch',
      name: 'Year End waterfall ',
      description: 'You can add the year where you want the waterfall to end.',
      defaultValue: false,
    })
    .addNumberInput({
      path: 'addyear',
      name: 'Input the year',
      defaultValue: new Date().getFullYear() + 3,
      description: '',
      showIf: (options: any) => options.endyearSwitch === true,
    })
    .addBooleanSwitch({
      path: 'barlineSwitch',
      name: 'Bar Lines',
      description: 'To show lines between bars',
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'labelSwitch',
      name: 'Show Label',
      description: 'To show labels',
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'total',
      name: 'Display Final Output',
      description: 'To show the sum of previous output and provide color to bar according to loss or gain',
      defaultValue: false,
    })
    .addTextInput({
      path: 'totalName',
      name: 'Rename Total',
      defaultValue: 'total',
      description: '',
      showIf: (options: any) => options.typeWaterfallPlot === 'query',
    })
    .addRadio({
      path: 'tooltip.mode',
      name: 'Tooltip mode',
      description: '',
      defaultValue: 'single',
      category: ['Tooltip & Legend'],
      settings: {
        options: [
          { value: 'single', label: 'Show' },
          { value: 'none', label: 'Hidden' },
        ],
      },
    });
  addLegendOptions(builder);
});
