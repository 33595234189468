// import { config } from '@grafana/runtime';

import { TimeRange, dateTime } from '@grafana/data';
import { QueryGroupOptions } from 'app/types';

// export const API_URL_SERVER = 'http://localhost:5002/';
export const API_URL_SERVER = '/api/carbon-ml/';
// export const MerlionUrl = `http://localhost:8050/_dash-update-component`;
// export const MerlionUrl = config.carbonMLBackendUrl;

export interface ModelDetails {
  modelName: string;
  timeRange: any;
  queryOptions: QueryGroupOptions;
}

export enum ModelStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
}

export interface DeleteModel {
  type: string;
  modelId: string;
  title: string;
  predictionAlgo: string;
  dbtype: string;
}

export interface Model {
  id: string;
  info: ModelInfo;
  isAnomaly: boolean;
  isEdit: boolean;
  status: {
    modelStatus: ModelStatus;
    msg: string;
    progress: number;
  };
  data: {
    forecast: {} | any;
    training: any;
    trainingWithForecast: any;
    trainingWithForecastTimerange: TimeRange;
  };
  metrics: {
    training: any;
    testing: any;
    selectedAlgo: string;
  };
}

export interface ClickhouseInfo {
  defaultDB: string;
  id: number;
}

export interface ModelInfo {
  userid: number;
  host: string;
  orgid: number;
  clickhouseInfo: string;
  modelId: string;
  type: string;
  name: string;
  filename: string;
  testFilename: string;
  alias: string;
  exog: string;
  exog_data: string;
  exog_filename: string;
  jobId: number;
  key: string;
  payload: string;
  csvData: string;
  testCsvData: string;
  result: string;
  metrics: string;
  modelPhase: string;
  metadata: string;
  schedule: string;
  cronValue: string;
  isRelative: string;
  timeFrom: string;
  timeTo: string;
  queries: string;
  status: string;
  errMsg: string;
  progressbar: number;
  done: string;
}

const DEFAULT_MODEL_INFO: ModelInfo = {
  userid: 0,
  host: '',
  orgid: 0,
  clickhouseInfo: '',
  modelId: '',
  type: '',
  name: '',
  filename: '',
  testFilename: '',
  alias: '',
  exog: '',
  exog_data: '',
  exog_filename: '',
  jobId: 0,
  key: '',
  payload: '',
  csvData: '',
  testCsvData: '',
  result: '',
  metrics: '',
  modelPhase: '',
  metadata: '',
  schedule: '',
  cronValue: '',
  isRelative: '',
  timeFrom: '',
  timeTo: '',
  queries: '',
  status: '',
  errMsg: '',
  progressbar: 0,
  done: '',
};

export const DEFAULT_MODEL: Model = {
  id: '',
  info: DEFAULT_MODEL_INFO,
  isAnomaly: false,
  isEdit: false,
  status: {
    modelStatus: ModelStatus.NotStarted,
    msg: '',
    progress: 0,
  },
  data: {
    forecast: {},
    training: null,
    trainingWithForecast: null,
    trainingWithForecastTimerange: {
      from: dateTime(Date.now() - 900000),
      to: dateTime(Date.now()),
      raw: { from: 'now-15m', to: 'now' },
    },
  },
  metrics: {
    training: null,
    testing: null,
    selectedAlgo: '',
  },
};

export interface ForecastModelMetaData {
  createdat: string;
  datacol: number;
  datarow: number;
  forecastcol: number;
  forecasting_prediction_interval: string;
  forecastrow: number;
  name: string;
  version: number;
}

export enum Tabs {
  TrainingData = 'trainingData',
  Query = 'query',
  TrainingMetrics = 'trainingMetrics',
}

export const trainingTabs = [
  { id: Tabs.Query, text: 'Query result' },
  { id: Tabs.TrainingMetrics, text: 'Training Metrics' },
];

export const editTabs = [
  { id: Tabs.TrainingData, text: 'Prediction/Training Data' },
  { id: Tabs.Query, text: 'Query result' },
  { id: Tabs.TrainingMetrics, text: 'Training Metrics' },
];

export const anomalyTabs = [{ id: Tabs.TrainingData, text: 'Prediction/Training Data' }];

export enum EditorTabs {
  Query = 'query',
  Transform = 'transform',
}

export interface PanelEditorTabI {
  id: string;
  text: string;
  icon: string;
}

export const PanelEditorTab: PanelEditorTabI[] = [
  { id: 'query', text: 'Query', icon: 'database' },
  { id: 'transform', text: 'Transform', icon: 'process' },
];

export const apiTypes = {
  forecastList: 'get models list',
  importModelStart: 'import model start',
  checkImportModelStatus: 'check model import status',
  addImportModelToMiddleware: 'add the imported model to middleware',
  downloadModelStart: 'download model start',
  downloadModelConfirm: 'confirm model download',
  deleteModelStart: 'delete model start',
  checkDeleteModelStatus: 'check model delete status',
  deleteModelFromMiddleware: 'delete model from middleware',
  deleteFileStart: 'delete file start',
  checkDeleteFileStatus: 'check file delete status',
  uploadFile: 'upload file',
  trainModel: 'train/retrain model',
  getAlgos: 'get alogorithm list',
  getAlgoSettings: 'get algorithm settings',
  getModelStatus: 'get model status',
  getModelDetails: 'get a model details',
  modelPredictStart: 'start model prediciton',
  getModelPredictionResults: 'get model predictions',
  getAnomalyModelsList: 'get anomaly detectors model types',
  getAnomalySetting: 'get anomaly detection settings',
  addAnomalyToMiddleware: 'add anomaly to middleware',
  anomalyDetectionStart: 'start anomaly detection',
  anomalyDetectionStatus: 'check anomaly detection status',
  getAnomalyModelListFromMiddleware: 'get anomaly model list',
  getAnomalyThresholdList: 'get anomaly threshold list',
  getAnomalyThresholdSettings: 'get anomaly threshold settings',
  updateSchedulerValue: 'change scheduler value',
};
