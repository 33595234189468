import React, { useCallback, useState, useEffect } from 'react';
import {
  AnnotationEvent,
  DataFrame,
  Field,
  FieldMatcherID,
  GrafanaTheme,
  PanelProps,
  TimeRange,
  fieldMatchers,
} from '@grafana/data';
import { GraphNG, GraphNGLegendEvent, TooltipPlugin, ZoomPlugin, useTheme } from '@grafana/ui';
import { changeSeriesColorConfigFactory } from './overrides/colorSeriesConfigFactory';
import { hideSeriesConfigFactory } from './overrides/hideSeriesConfigFactory';
import { ContextMenuItemClickPayload, ContextMenuPlugin } from './plugins/ContextMenuPlugin';
import { Options } from './types';
import { AnnotationsPluginForAlert } from './AlertAnnotations/AnnotationsPluginForAlert';
import { annotationListPrepare, annotationPrepare, getAlertRuleList } from './AlertAnnotations/AnnotationsPrepareAlert';
import { ExemplarsPlugin } from './plugins/ExemplarsPlugin';
import { getFieldLinksForExplore } from 'app/features/explore/utils/links';
import { AnnotationsPlugin } from './plugins/AnnotationsPlugin';
import { AnnotationEditorPlugin } from './AnnotationEditorPlugin';
import { preparePlotFrame2 } from '@grafana/ui/src/components/GraphNG2/utils';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';
import { AnnotationsSrv } from 'app/features/annotations/annotations_srv';

interface TimeSeriesPanelProps extends PanelProps<Options> {}

export const TimeSeriesPanel: React.FC<TimeSeriesPanelProps> = ({
  data,
  timeRange,
  timeZone,
  width,
  height,
  options,
  fieldConfig,
  onChangeTimeRange,
  onFieldConfigChange,
  replaceVariables,
  id,
  panel,
  annotationList,
  forecastingPanel,
}) => {
  const [alertRuleList, setAlertRuleList] = useState([]);
  const [panelAnnotationList, setAnnotations] = useState<AnnotationEvent[]>([]);
  const timeFrom = timeRange.from.valueOf();
  const timeTo = timeRange.to.valueOf();
  const theme = useTheme();

  const annotationsService = new AnnotationsSrv();

  const onLegendClick = useCallback(
    (event: GraphNGLegendEvent) => {
      onFieldConfigChange(hideSeriesConfigFactory(event, fieldConfig, data.series));
    },
    [fieldConfig, onFieldConfigChange, data.series]
  );

  const getFieldLinks = (field: Field, rowIndex: number) => {
    return getFieldLinksForExplore({ field, rowIndex, range: timeRange });
  };

  const onSeriesColorChange = useCallback(
    (label: string, color: string) => {
      onFieldConfigChange(changeSeriesColorConfigFactory(label, color, fieldConfig));
    },
    [fieldConfig, onFieldConfigChange]
  );

  const getAndSetAnnotations = async () => {
    // todo: get annotations for all time-series panels in the dashboard at once
    // todo: the getAlertRuleList method is called for every panel in the dashboard individually which should not be the case
    // todo: for now one more case for open dashboard has been added in the getAlertRuleList method and respective api is being hit
    // todo: but in the future all the annotations will be fetched for every time-series panel at once for a dashboard
    // todo: hittig api for every panel individually should be avoided
    const response = await getAlertRuleList(id);
    if (annotationList) {
      const panelAnnotationList = annotationList.filter(
        (annotation: AnnotationEvent) => annotation.panelId === data.request?.panelId
      );
      setAnnotations(panelAnnotationList);
    } else {
      const dash = getDashboardSrv().getCurrent();
      const anno = await annotationsService.getAnnotations({
        dashboard: dash,
        panel: panel,
        range: timeRange,
      });
      if (!Array.isArray(anno)) {
        setAnnotations(anno.annotations);
      }
    }
    setAlertRuleList(response);
  };

  // * fetching All alerts from backend which are related to specific panel and dashboard
  useEffect(() => {
    if (!forecastingPanel) {
      getAndSetAnnotations();
    }
  }, [data]);

  // const alertRuleList = useMemo(() => getAlertRuleList(id), [data]);

  if (!data || !data.series?.length || !data.series[0]?.length) {
    return (
      <div className="panel-empty">
        <p>No data found in response</p>
      </div>
    );
  }
  const annotationDataFrame = annotationPrepare(alertRuleList, timeFrom, timeTo);
  const annotationsListDataFrame = annotationListPrepare(panelAnnotationList);

  if (annotationsListDataFrame) {
    data.annotations = annotationsListDataFrame;
  }

  // Getting frame value.
  const frame = getDataFrame(data.series, theme, timeRange, timeZone);

  return (
    <GraphNG
      data={data.series}
      timeRange={timeRange}
      timeZone={timeZone}
      width={width}
      height={height}
      legend={options.legend}
      onLegendClick={onLegendClick}
      onSeriesColorChange={onSeriesColorChange}
    >
      <ZoomPlugin onZoom={onChangeTimeRange} />
      <TooltipPlugin data={data.series} mode={options.tooltipOptions.mode} timeZone={timeZone} isTimeSeries={true} />

      {data.annotations && (
        <ExemplarsPlugin exemplars={data.annotations} timeZone={timeZone} getFieldLinks={getFieldLinks} />
      )}
      {data.annotations && (
        <AnnotationsPlugin annotations={data.annotations} timeZone={timeZone} panelID={data.request?.panelId ?? 0} />
      )}

      {/* Enables annotations creation*/}
      <AnnotationEditorPlugin data={frame!} timeZone={timeZone} panelID={data.request?.panelId ?? 0}>
        {({ startAnnotating }) => {
          return (
            <ContextMenuPlugin
              data={data.series}
              timeZone={timeZone}
              replaceVariables={replaceVariables}
              defaultItems={[
                {
                  items: [
                    {
                      label: 'Add annotation',
                      ariaLabel: 'Add annotation',
                      icon: 'message',
                      onClick: (e: any, p: ContextMenuItemClickPayload) => {
                        if (!p) {
                          return;
                        }
                        startAnnotating({ coords: p.coords });
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </AnnotationEditorPlugin>

      {annotationDataFrame && <AnnotationsPluginForAlert annotations={annotationDataFrame} timeZone={timeZone} />}
    </GraphNG>
  );
};

const getDataFrame = (data: DataFrame[], theme: GrafanaTheme, timeRange: TimeRange, timeZone: string) => {
  const dimFields = {
    x: fieldMatchers.get(FieldMatcherID.firstTimeField).get({}),
    y: fieldMatchers.get(FieldMatcherID.numeric).get({}),
  };
  const frame = preparePlotFrame2(data, dimFields);

  return frame ?? null;
};
