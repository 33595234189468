import { LoadingState, PanelData, dateTime } from '@grafana/data';
import { jsonStringToPanelData } from './jsonStringToPanelData';

var alphabetArray = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

export function calculateTimeDifference(timestamp: string) {
  // Convert timestamp to milliseconds
  var currentTime = Date.now();
  var diff = new Date(timestamp).getTime() - currentTime;

  // Calculate the difference in milliseconds, seconds, minutes, hours, and days
  var diffInSeconds = Math.floor(diff / 1000);
  var diffInMinutes = Math.floor(diffInSeconds / 60);
  var diffInHours = Math.floor(diffInMinutes / 60);
  var diffInDays = Math.floor(diffInHours / 24);

  // Determine the time unit and value
  var unit, value;
  if (Math.abs(diffInDays) >= 365) {
    unit = 'y';
    value = Math.floor(diffInDays / 365);
  } else if (Math.abs(diffInDays) >= 30) {
    unit = 'M';
    value = Math.floor(diffInDays / 30);
  } else if (Math.abs(diffInDays) >= 1) {
    unit = 'd';
    value = diffInDays;
  } else if (Math.abs(diffInHours) >= 1) {
    unit = 'h';
    value = diffInHours;
  } else if (Math.abs(diffInMinutes) >= 1) {
    unit = 'm';
    value = diffInMinutes;
  } else {
    unit = 's';
    value = diffInSeconds;
  }

  // Create the formatted string
  var formattedDiff = (diff < 0 ? '-' : '+') + Math.abs(value) + unit;

  return formattedDiff;
}

/**
 * Converts forecast data into a series format.
 * @param {PanelData} dataObj - The panel data object.
 * @param {any} forecast - The forecast data.
 * @param {(e: any) => void} onUpdateTimeRange - The function to update the time range.
 * @returns {PanelData} The updated panel data object.
 */
export const convertToSeries = (dataObj: PanelData, forecast: any) => {
  const { x: timeArray } = forecast;

  var jsonResultData = [];
  for (let i = 0; i < forecast.x.length; i++) {
    const Time = forecast.x[i];
    const Value = forecast.y[i];
    jsonResultData.push({ Time: Time, [forecast.alias]: Value });
  }
  var resultData = jsonStringToPanelData(JSON.stringify(jsonResultData));
  var finalDataSeries = dataObj;
  resultData.series.map((element) => finalDataSeries.series.push(element));
  finalDataSeries.series.map((element, i) => {
    element.refId = alphabetArray[i];
    return element;
  });

  const startTime = dataObj
    ? dataObj?.series[0].fields.find((ele) => ele.type === 'time')?.values.toArray()[0]
    : timeArray[0];
  const endTime = timeArray[timeArray.length - 1];
  finalDataSeries.timeRange = {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {
      from: `now${calculateTimeDifference(startTime)}`,
      to: `now${calculateTimeDifference(endTime)}`,
    },
  };
  finalDataSeries.state = LoadingState.Done;
  return finalDataSeries;
};
