import Handlebars from 'handlebars';
import MarkdownIt from 'markdown-it';
import {
  FieldConfigSource,
  FieldDisplay,
  getFieldDisplayValues,
  getLocale,
  GrafanaTheme,
  InterpolateFunction,
  PanelData,
  textUtil,
  TimeRange,
} from '@grafana/data';
import { config } from '@grafana/runtime';
import { registerHelpers } from './handlebars';
import { TextOptions } from '../types';
/**
 * Helpers
 */
registerHelpers(Handlebars);
/**
 * Generate HTML according to new dynamic text.
 */
export const generateHtml = (
  data: Record<string, any>,
  content: string,
  options: TextOptions,
  timeRange: TimeRange,
  theme: GrafanaTheme,
  fieldConfig: FieldConfigSource<any>,
  replaceVariables: InterpolateFunction,
  timeZone: string,
  dataseries: PanelData
) => {
  const { helpers, reduceOptions } = options;
  const getValues = (): FieldDisplay[] => {
    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: reduceOptions,
      replaceVariables,
      theme: theme,
      data: dataseries.series,
      timeZone,
    });
  };

  getValues().map((frame: any) => {
    data[frame.display.title] = (frame.display.prefix || '') + frame.display.text + (frame.display.suffix || '');
    data[`_${frame.display.title}`] = Number(frame.display.text);
  });

  // Load Dashboard Time
  data['StartTime'] = timeRange.from;
  data['EndTime'] = timeRange.to;
  data['MomentTime'] = timeRange;

  /**
   * Add Custom Helpers
   */
  if (helpers) {
    const func = new Function('data', 'handlebars', 'getLocale', helpers);
    func(data, Handlebars, getLocale, helpers);
  }

  /**
   * Handlebars
   */
  const template = Handlebars.compile(content ?? '');
  const markdown = template(data);

  /**
   * Render Markdown
   */
  const md = new MarkdownIt({ html: true });
  const html = md.render(markdown);

  /**
   * Skip sanitizing if disabled in Grafana
   */
  if (config.disableSanitizeHtml) {
    return html;
  }

  /**
   * Return sanitized HTML
   */
  return textUtil.sanitize(html);
};
