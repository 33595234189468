import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplyFieldOverrideOptions, DataFrame, DataTransformerConfig, dateTime, FieldColorModeId } from '@grafana/data';
import alertDef from './alertDef';
import {
  AlertDefinition,
  AlertDefinitionDTO,
  AlertDefinitionQueryModel,
  AlertDefinitionState,
  AlertDefinitionUiState,
  AlertRule,
  AlertRuleDTO,
  AlertRulesState,
  NotificationChannelOption,
  NotificationChannelState,
  NotifierDTO,
  QueryGroupOptions,
  SilenceState,
} from 'app/types';
import store from 'app/core/store';
import { config } from '@grafana/runtime';
import { PanelQueryRunner } from '../../../query/state/PanelQueryRunner';

// initial state variables
export const ALERT_DEFINITION_UI_STATE_STORAGE_KEY = 'grafana.alerting.alertDefinition.ui';
const DEFAULT_ALERT_DEFINITION_UI_STATE: AlertDefinitionUiState = { rightPaneSize: 400, topPaneSize: 0.45 };

export const initialState: AlertRulesState = {
  items: [],
  searchQuery: '',
  isLoading: false,
};

//* initial state of the notification channel.
export const initialChannelState: NotificationChannelState = {
  notificationChannelTypes: [],
  notificationChannel: {},
  notifiers: [],
};

const options: ApplyFieldOverrideOptions = {
  fieldConfig: {
    defaults: {
      color: {
        mode: FieldColorModeId.PaletteClassic,
      },
    },
    overrides: [],
  },
  replaceVariables: (v: string) => v,
  theme: config.theme,
};

const dataConfig = {
  getTransformations: () => [] as DataTransformerConfig[],
  getFieldOverrideOptions: () => options,
  getAnomaly: () => undefined,
};

//* initial state of the alert rule, query options
export const initialAlertDefinitionState: AlertDefinitionState = {
  alertDefinition: {
    for: '5m',
    id: 0,
    uid: '',
    title: '',
    channel: '',
    description: '',
    condition: '',
    data: [],
    intervalSeconds: '1m',
    stateClass: {},
    stateAge: '',
    tags: [],
    panel_uid: 0,
    dashboard_uid: '',
    dashboard_name: '',
    db_name: config.defaultDatasource,
    db_default: true,
    db_uid: '',
    NoDataState: 'Normal',
    ExecErrState: 'Alerting',
    minInterval: '10m',
    maxDataPoints: 100,
  },
  queryRunner: new PanelQueryRunner(dataConfig),
  uiState: { ...store.getObject(ALERT_DEFINITION_UI_STATE_STORAGE_KEY, DEFAULT_ALERT_DEFINITION_UI_STATE) },
  data: [],
  alertDefinitions: [] as AlertDefinition[],
  /* These are functions as they are mutated later on and redux toolkit will Object.freeze state so
   * we need to store these using functions instead */
  getInstances: () => [] as DataFrame[],
  // Default query options
  getQueryOptions: () => ({
    minInterval: '10m',
    maxDataPoints: 100,
    dataSource: { name: config.defaultDatasource },
    queries: [],
  }),
};

// timeRange: { from: 600, to: 0, raw: { from: 'now - 10m', to: 'now'}}
//* initial state of the silence
const initialSilenceState: SilenceState = {
  from: dateTime().subtract(6, 'h'),
  to: dateTime(),
  duration: '',
  rulename: '',
  comment: '',
  createdby: '',
  pause: false,
};

//* initial state of the timerange
export const initialTimeRange: any = {
  timeRange: {
    from: dateTime().subtract(10, 'm'),
    to: dateTime(),
    raw: {
      from: 'now-10m',
      to: 'now',
    },
  },
};

function convertToAlertRule(dto: AlertRuleDTO, state: string): AlertRule {
  const stateModel = alertDef.getStateDisplayModel(state);

  const rule: AlertRule = {
    ...dto,
    stateText: stateModel.text,
    stateIcon: stateModel.iconClass,
    stateClass: stateModel.stateClass,
    stateAge: dateTime(dto.newStateDate).fromNow(true),
  };

  if (rule.state !== 'paused') {
    if (rule.executionError) {
      rule.info = 'Execution Error: ' + rule.executionError;
    }
    if (rule.evalData && rule.evalData.noData) {
      rule.info = 'Query returned no data';
    }
  }

  return rule;
}

//* reducer consisting the initial state of the timerange and method to update the timeRange
//* for which the data is selected.
const timeRangeSlice = createSlice({
  name: 'timeRange',
  initialState: initialTimeRange,
  reducers: {
    setTimeRange: (state, action: PayloadAction<string>): any => {
      return { ...state, timeRange: action.payload };
    },
  },
});

const alertRulesSlice = createSlice({
  name: 'alertRules',
  initialState,
  reducers: {
    loadAlertRules: (state) => {
      return { ...state, isLoading: true };
    },
    loadedAlertRules: (state, action: PayloadAction<AlertRuleDTO[]>): AlertRulesState => {
      const alertRules: AlertRuleDTO[] = action.payload;

      const alertRulesViewModel: AlertRule[] = alertRules.map((rule) => {
        return convertToAlertRule(rule, rule.state);
      });

      return { ...state, items: alertRulesViewModel, isLoading: false };
    },
    setSearchQuery: (state, action: PayloadAction<string>): AlertRulesState => {
      return { ...state, searchQuery: action.payload };
    },
  },
});

//* reducers consisting the initial state of the notification channel and method which will update
//* the state of the notification channel.
const notificationChannelSlice = createSlice({
  name: 'notificationChannel',
  initialState: initialChannelState,
  reducers: {
    setNotificationChannels: (state, action: PayloadAction<NotifierDTO[]>): NotificationChannelState => {
      return {
        ...state,
        notificationChannelTypes: transformNotifiers(action.payload),
        notifiers: action.payload,
      };
    },
    notificationChannelLoaded: (state, action: PayloadAction<any>): NotificationChannelState => {
      const notificationChannel = action.payload;
      const selectedType: NotifierDTO = state.notifiers.find((t) => t.type === notificationChannel.type)!;
      const secureChannelOptions = selectedType.options.filter((o: NotificationChannelOption) => o.secure);
      /*
        If any secure field is in plain text we need to migrate it to use secure field instead.
       */
      if (
        secureChannelOptions.length > 0 &&
        secureChannelOptions.some((o: NotificationChannelOption) => {
          return notificationChannel.settings[o.propertyName] !== '';
        })
      ) {
        return migrateSecureFields(state, action.payload, secureChannelOptions);
      }

      return { ...state, notificationChannel: notificationChannel };
    },
    resetSecureField: (state, action: PayloadAction<string>): NotificationChannelState => {
      return {
        ...state,
        notificationChannel: {
          ...state.notificationChannel,
          secureFields: { ...state.notificationChannel.secureFields, [action.payload]: false },
        },
      };
    },
  },
});

//* reducers consisting the initial state of the alert rule and method which will update
//* the state of the alert rule.
const alertDefinitionSlice = createSlice({
  name: 'alertDefinition',
  initialState: initialAlertDefinitionState,
  reducers: {
    setAlertDefinition: (state: AlertDefinitionState, action: PayloadAction<AlertDefinitionDTO>) => {
      // const currentOptions = state.getQueryOptions();
      state.alertDefinition.title = action.payload.title;
      state.alertDefinition.id = action.payload.id;
      state.alertDefinition.uid = action.payload.uid;
      state.alertDefinition.condition = action.payload.condition;
      state.alertDefinition.intervalSeconds = action.payload.intervalSeconds;
      state.alertDefinition.data = action.payload.data;
      state.alertDefinition.description = action.payload.description;
      state.alertDefinition.for = action.payload.For;
      state.alertDefinition.channel = action.payload.channel;
      state.alertDefinition.tags = action.payload.tags;
      state.alertDefinition.panel_uid = action.payload.panel_uid;
      state.alertDefinition.dashboard_uid = action.payload.dashboard_uid;
      state.alertDefinition.dashboard_name = action.payload.dashboard_name;
      state.alertDefinition.db_name = action.payload.db_name;
      state.alertDefinition.db_default = action.payload.db_default;
      state.alertDefinition.db_uid = action.payload.db_uid;
      state.alertDefinition.NoDataState = action.payload.NoDataState;
      state.alertDefinition.ExecErrState = action.payload.ExecErrState;
      state.alertDefinition.maxDataPoints = action.payload.maxDataPoints;
      state.alertDefinition.minInterval = action.payload.minInterval;
      state.getQueryOptions = () => ({
        // ...currentOptions,
        dataSource: { name: action.payload.db_name, default: action.payload.db_default, uid: action.payload.db_uid },
        queries: action.payload.data.map((q: AlertDefinitionQueryModel) => ({ ...q.model })),
        maxDataPoints: action.payload.maxDataPoints,
        minInterval: action.payload.minInterval,
      });
    },
    updateAlertDefinitionOptions: (state: AlertDefinitionState, action: PayloadAction<Partial<AlertDefinition>>) => {
      state.alertDefinition = { ...state.alertDefinition, ...action.payload };
    },
    setUiState: (state: AlertDefinitionState, action: PayloadAction<AlertDefinitionUiState>) => {
      state.uiState = { ...state.uiState, ...action.payload };
    },
    setQueryOptions: (state: AlertDefinitionState, action: PayloadAction<QueryGroupOptions>) => {
      state.getQueryOptions = () => action.payload;
    },
    setAlertDefinitions: (state: AlertDefinitionState, action: PayloadAction<AlertDefinition[]>) => {
      state.alertDefinitions = action.payload;
    },
    setInstanceData: (state: AlertDefinitionState, action: PayloadAction<DataFrame[]>) => {
      state.getInstances = () => action.payload;
    },
    cleanUpState: (state: AlertDefinitionState, action: PayloadAction<undefined>) => {
      if (state.queryRunner) {
        state.queryRunner.destroy();
        state.queryRunner = undefined;
        delete state.queryRunner;
        state.queryRunner = new PanelQueryRunner(dataConfig);
      }

      state.alertDefinitions = initialAlertDefinitionState.alertDefinitions;
      state.alertDefinition = initialAlertDefinitionState.alertDefinition;
      state.data = initialAlertDefinitionState.data;
      state.getInstances = initialAlertDefinitionState.getInstances;
      state.getQueryOptions = initialAlertDefinitionState.getQueryOptions;
      state.uiState = initialAlertDefinitionState.uiState;
    },
  },
});

//* reducers consisting the initial state of the silence and method which will update
//* the state of the silence.
const silenceSlice = createSlice({
  name: 'Silence',
  initialState: initialSilenceState,
  reducers: {
    updateSilence: (state: SilenceState, action: PayloadAction<Partial<SilenceState>>) => {
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.duration = action.payload.duration;
      state.comment = action.payload.comment;
      state.createdby = action.payload.createdby;
      state.rulename = action.payload.rulename;
      state.pause = action.payload.pause;
    },
  },
});

export const { loadAlertRules, loadedAlertRules, setSearchQuery } = alertRulesSlice.actions;

export const { setTimeRange } = timeRangeSlice.actions;

export const {
  setNotificationChannels,
  notificationChannelLoaded,
  resetSecureField,
} = notificationChannelSlice.actions;

export const {
  setUiState,
  updateAlertDefinitionOptions,
  setQueryOptions,
  setAlertDefinitions,
  setAlertDefinition,
  setInstanceData,
  cleanUpState,
} = alertDefinitionSlice.actions;

export const { updateSilence } = silenceSlice.actions;

export const alertRulesReducer = alertRulesSlice.reducer;
export const notificationChannelReducer = notificationChannelSlice.reducer;
export const alertDefinitionsReducer = alertDefinitionSlice.reducer;
export const silenceReducer = silenceSlice.reducer;
export const timeRangeReducer = timeRangeSlice.reducer;

export default {
  alertRules: alertRulesReducer,
  notificationChannel: notificationChannelReducer,
  alertDefinition: alertDefinitionsReducer,
  silence: silenceReducer,
  timeRange: timeRangeReducer,
};

function migrateSecureFields(
  state: NotificationChannelState,
  notificationChannel: any,
  secureChannelOptions: NotificationChannelOption[]
) {
  const cleanedSettings: { [key: string]: string } = {};
  const secureSettings: { [key: string]: string } = {};

  secureChannelOptions.forEach((option) => {
    secureSettings[option.propertyName] = notificationChannel.settings[option.propertyName];
    cleanedSettings[option.propertyName] = '';
  });

  return {
    ...state,
    notificationChannel: {
      ...notificationChannel,
      settings: { ...notificationChannel.settings, ...cleanedSettings },
      secureSettings: { ...secureSettings },
    },
  };
}

function transformNotifiers(notifiers: NotifierDTO[]) {
  return notifiers
    .map((option: NotifierDTO) => {
      return {
        value: option.type,
        label: option.name,
        ...option,
        typeName: option.type,
      };
    })
    .sort((o1, o2) => {
      if (o1.name > o2.name) {
        return 1;
      }
      return -1;
    });
}
